import React from "react";
import { adminMenu, userMenu } from "../Data";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  BsBellFill,
  BsHospital,
  BsHouse,
  BsMoonFill,
  BsPersonFill,
  BsSunFill,
} from "react-icons/bs";
import { useSelector } from "react-redux";
import { userSelect } from "../app/redux/features/userSlice";
import { FaHandHoldingMedical } from "react-icons/fa";
import { message, Badge } from "antd";

import {
  Box,
  Flex,
  Avatar,
  HStack,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  Center,
  useColorMode,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";

export default function Layout({ children }) {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useSelector(userSelect);
  // =========== doctor menu ===============
  const doctorMenu = [
    {
      name: "Home",
      path: "/",
      icon: <BsHouse className=" size-6" />,
    },
    {
      name: "Appointments",
      path: "/doctor-appointments",
      icon: <FaHandHoldingMedical className=" size-6" />,
    },
    {
      name: "Apply Doctor",
      path: "/apply-doctor",
      icon: <BsHospital className=" size-6" />,
    },

    {
      name: "Profile",
      path: `/doctor/profile/${user?._id}`,
      icon: <BsPersonFill className=" size-6" />,
    },
  ];
  // =========== doctor menu ===============

  // rendering menu list
  const Links = user?.isAdmin
    ? adminMenu
    : user?.isDoctor
    ? doctorMenu
    : userMenu;
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    message.success("Logout successfully");
    navigate("/login");
  };
  return (
    <>
      <Box bg={useColorModeValue("gray.100", "gray.900")} px={4}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={"center"}>
            <Button variant={"solid"} colorScheme={"teal"} size={"sm"}>
              Medical Clinic
            </Button>
            <HStack
              className="navbar text-teal-700 "
              as={"nav"}
              spacing={8}
              display={{ base: "none", md: "flex" }}
            >
              {Links.map((link) => (
                <NavLink
                  className="flex min-w-fit items-center gap-1"
                  key={link.name}
                  as="a"
                  px={2}
                  py={1}
                  rounded={"md"}
                  _hover={{
                    textDecoration: "none",
                  }}
                  to={link.path}
                >
                  {link.icon} {link.name}
                </NavLink>
              ))}
            </HStack>
          </HStack>
          <Flex alignItems={"center"} className=" flex items-center gap-2">
            <Button onClick={toggleColorMode}>
              {colorMode === "light" ? <BsMoonFill /> : <BsSunFill />}
            </Button>

            <Menu>
              <MenuButton
                as={Button}
                rounded={"full"}
                variant={"link"}
                cursor={"pointer"}
                minW={0}
              >
                {" "}
                <Badge
                  count={user?.notifcation.length}
                  className=" cursor-pointer"
                >
                  <Avatar
                    size={"sm"}
                    src={"https://avatars.dicebear.com/api/male/username.svg"}
                  />{" "}
                </Badge>
              </MenuButton>
              <MenuList alignItems={"center"}>
                <br />
                <Center>
                  <Avatar
                    size={"2xl"}
                    src={"https://avatars.dicebear.com/api/male/username.svg"}
                  />
                </Center>
                <br />
                <Center>
                  <p>{user?.name}</p>
                </Center>
                <br />
                <MenuDivider />
                <MenuItem as={Link} to="/notification">
                  {" "}
                  <Badge
                    count={user?.notifcation.length}
                    className=" cursor-pointer"
                  >
                    <BsBellFill className="text-[1.2rem] " />
                  </Badge>
                  Notifications
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} className="navbar" spacing={4}>
              {Links.map((link) => (
                <NavLink className="flex gap-2 " key={link.name} to={link.path}>
                  {link.icon} {link.name}
                </NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>

      <Box
        mx={"auto"}
        p={8}
        className=" flex flex-col justify-center items-center  mx-auto  min-w-[90%]"
      >
        {children}{" "}
      </Box>
    </>
  );
}
