import * as React from "react";
import { Spin } from "antd";

export default function Spinner() {
  return (
    <div className=" flex justify-center  items-center h-screen z-50">
      <Spin />
    </div>
  );
}
